import { Grid } from 'pages/resources/styles';
import { Content } from 'pages/resources/content';
import React from 'react';
import { Skeleton } from 'components/skeleton';
import { useSelector } from 'react-redux';
import { selectGetResourcesLoadingStatus, selectResources } from 'store/resources/selectors';

interface ResourcesListProps {
  isCompactUiEnabled: boolean;
}

export const ResourcesList = ({ isCompactUiEnabled }: ResourcesListProps): JSX.Element => {
  const resources = useSelector(selectResources);
  const isResourcesLoading = useSelector(selectGetResourcesLoadingStatus);
  const isNoResources = resources?.length === 0 && !isResourcesLoading;

  return isResourcesLoading ? (
    <Skeleton isCompactUiEnabled={isCompactUiEnabled} />
  ) : (
    <Grid isNoResources={isNoResources} isCompactUiEnabled={isCompactUiEnabled}>
      <Content resources={resources} />
    </Grid>
  );
};
