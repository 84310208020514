import { ACTION_STATUSES, DefaultFilterEnum, FontLocation } from 'shared/consts';
import { ThemeLocationFont, useThemeFont } from 'hooks/use-theme-font.hook';
import { selectAppInstanceConfigs } from 'store/app-instance-configs/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AppInstanceConfig } from 'store/app-instance-configs/types';
import { selectPatchTransactionsStatus, selectTransactions } from 'store/transactions/selectors';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport/';
import { useLoadingHandler } from './loading-handler.hook';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { HEADER_BUTTON_MODE } from '@hqo/hqo-miniapp-client-sdk';
import { selectIsHeaderSet } from 'store/header-state/selectors';
import { setHeaderAction } from 'store/header-state/actions';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';

interface UseLandingPageReturnValues {
  shouldShowSkeleton: boolean;
  isMobileDevice: boolean;
  showAvailableNow: boolean;
  showWhenIsBooking: boolean;
  showResourcesList: boolean;
  showFloorPlan: boolean;
  isCompactUiEnabled: boolean;
  themeFontTitle: ThemeLocationFont;
  appInstanceConfigs: Array<AppInstanceConfig>;
  defaultFilter: DefaultFilterEnum;
}

export const useLandingPageData = (): UseLandingPageReturnValues => {
  const isMobileDevice = useIsSmallViewportWidth();
  const themeFontTitle = useThemeFont(FontLocation.TITLE);
  const dispatch = useDispatch();
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const transactions = useSelector(selectTransactions);
  const patchTransactionsStatus = useSelector(selectPatchTransactionsStatus);
  const client = useMiniappSdk();
  const isHeaderSet = useSelector(selectIsHeaderSet);
  const { hideResourceBookingAvailableNow: isAvailableNowFeatureHidden, showFloorPlan } = useFlags();
  const isDataLoading = useLoadingHandler();
  const { defaultFilter, isAvailableNowEnabled, isNativeAdapter, hideWhenIsBooking, isCompactUiEnabled } =
    useAppInstanceConfigFeature();

  const shouldShowSkeleton = isDataLoading || !transactions;
  const showResourcesList = defaultFilter === DefaultFilterEnum.NONE;

  const showAvailableNow = useMemo(() => {
    return isAvailableNowEnabled && !showResourcesList && !isAvailableNowFeatureHidden;
  }, [isAvailableNowEnabled, isAvailableNowFeatureHidden, showResourcesList]);

  const showWhenIsBooking = useMemo(() => {
    if (isNativeAdapter) {
      return !hideWhenIsBooking;
    }
    return true;
  }, [isNativeAdapter, hideWhenIsBooking]);

  useEffect(() => {
    if (!isHeaderSet && patchTransactionsStatus !== ACTION_STATUSES.PENDING && !isDataLoading) {
      dispatch(setHeaderAction());
      client?.header.setHeader('', HEADER_BUTTON_MODE.CLOSE);
      client?.header.showHeader();
      client?.swipe.disableBackSwipe();
    }
  }, [isHeaderSet, patchTransactionsStatus, isDataLoading, client, dispatch]);

  return {
    isCompactUiEnabled,
    defaultFilter,
    showResourcesList,
    showAvailableNow,
    showWhenIsBooking,
    showFloorPlan,
    shouldShowSkeleton,
    isMobileDevice,
    themeFontTitle,
    appInstanceConfigs,
  };
};
