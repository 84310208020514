import { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';
import { replace } from 'store/router/actions';
import { getStartTimeArray } from 'utils';
import { useLocale } from 'hooks/use-locale.hook';
import { QUERY_PARAMS } from 'shared/consts';
import { pick } from 'utils/pickObjectProperty';
import {
  searchParams,
  timeFormatOptions,
  Item,
  UseTimeSelectorOptions,
  useTimeSelectorDefaultOptions,
} from '../time-selector.interface';
import { getClosestTimeSlot } from 'utils/getStartTimeArray';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import { MIDNIGHT_HOURS, MIDNIGHT_HOURS_24H } from 'components/schedule-select/const';
import { useMobileTimePickerDropdowns } from 'components/time-selector/hooks/use-mobile-time-picker-dropdowns.hook';
import { resolveSafariMidnight } from 'components/time-selector/hooks/utils/resolveSafariMidnight';
import { isSameDay } from 'date-fns';

interface UseTimeValueOptions {
  isEndTime: boolean;
}

export const resolveMidNightValue = (
  encodedTime: string,
  timeValues: Item[],
  locale: string,
  options?: UseTimeValueOptions,
) => {
  const hoursFormattingOptions: Intl.DateTimeFormatOptions = options?.isEndTime &&
    encodedTime === '24:00' && { hour12: false };

  return encodedTime === '00:00' || encodedTime === '24:00'
    ? timeValues[timeValues.length - 1]
    : timeValues.find(
        ({ value }) =>
          value.toLocaleString(locale, { ...timeFormatOptions, ...hoursFormattingOptions }) === encodedTime,
      );
};

export const useTimeValue = (timeValues: Item[], encodedTime: string, options?: UseTimeValueOptions) => {
  const [time, setTime] = useState<Date>(null);
  const locale = useLocale();

  const setTimeValue = useCallback(
    (value: Date) => {
      setTime(value);
    },
    [setTime],
  );

  const setEncodedTime = useCallback(() => {
    if (encodedTime) {
      const newTime = timeValues.find(({ value }) => value.toLocaleString(locale, timeFormatOptions) === encodedTime);
      setTime(newTime.value);
    } else {
      setTime(null);
    }
  }, [encodedTime, timeValues, setTime, locale]);

  useEffect(() => {
    if (encodedTime) {
      const newTime = resolveMidNightValue(encodedTime, timeValues, locale, options);

      setTime(newTime.value);
    }
  }, [encodedTime, timeValues, setTime, locale]);

  return { time, setTime, setTimeValue, setEncodedTime };
};

interface UseTimeValues {
  startTimeValues: Item[];
  encodedStartTime: string;
  encodedEndTime: string;
}

const useTimeValues = ({ startTimeValues, encodedStartTime, encodedEndTime }: UseTimeValues) => {
  const {
    time: startTime,
    setTime: setStartTime,
    setTimeValue: onSelectStartTime,
    setEncodedTime: setEncodedStartTime,
  } = useTimeValue(startTimeValues, encodedStartTime, { isEndTime: false });

  const {
    time: endTime,
    setTime: setEndTime,
    setTimeValue: onSelectEndTime,
    setEncodedTime: setEncodedEndTime,
  } = useTimeValue(startTimeValues, encodedEndTime, { isEndTime: true });

  return {
    startTime,
    endTime,
    setStartTime,
    setEndTime,
    onSelectStartTime,
    onSelectEndTime,
    setEncodedStartTime,
    setEncodedEndTime,
  };
};

const useTimePickerTimeData = () => {
  const locale = useLocale();
  const startTimeValues = useMemo(() => getStartTimeArray(locale), [locale]);
  const [endTimeValues, setEndTimeValues] = useState<Array<Item>>([]);

  const { startTime: encodedStartTime, endTime: encodedEndTime } = useSearchParams<searchParams>();
  const { startTime, ...restTimeValues } = useTimeValues({ startTimeValues, encodedStartTime, encodedEndTime });

  const getEndTimeValues = useCallback(() => {
    const indexOfSlicedElement =
      startTime.getHours() === 0 && startTime.getMinutes() === 0
        ? 1
        : startTimeValues.findIndex(item => new Date(item.value).toISOString() > new Date(startTime).toISOString());

    return startTimeValues.slice(indexOfSlicedElement);
  }, [startTime, startTimeValues]);

  useEffect(() => {
    if (startTime) {
      setEndTimeValues(getEndTimeValues());
    }
  }, [startTimeValues, setEndTimeValues, startTime, getEndTimeValues]);

  return { startTimeValues, endTimeValues, startTime, ...restTimeValues };
};

export const useInvalidDuration = () => {
  const dispatch = useDispatch();
  const { duration, startTime, endTime, ...restQueryParams } = useSearchParams<searchParams>();
  if (!startTime || !endTime) {
    return;
  }
  const diff = bookingTimeHandler({
    startTime: startTime?.toString(),
    endTime: endTime?.toString(),
  }).getTimeDiff();

  if (diff < +duration) {
    const queryParams = startTime && endTime ? { startTime, endTime, ...restQueryParams } : restQueryParams;
    const queryString = qs.stringify(
      pick(
        queryParams,
        QUERY_PARAMS.filter(param => param !== 'duration'),
      ),
    );
    dispatch(replace(`${location.pathname}?${queryString}`));
  }
};

const useTimeFilter = () => {
  const dispatch = useDispatch();
  const { startTime, endTime, setStartTime, setEndTime, ...restTimeValues } = useTimePickerTimeData();
  const { startTime: encodedStartTime, endTime: encodedEndTime, ...restQueryParams } = useSearchParams<searchParams>();
  useInvalidDuration();

  useEffect(() => {
    if (!encodedStartTime && !encodedEndTime) {
      setStartTime(null);
      setEndTime(null);
    }
  }, [encodedStartTime, encodedEndTime, setStartTime, setEndTime]);

  useEffect(() => {
    if (!endTime) {
      return;
    }
    if (startTime >= endTime && encodedStartTime !== MIDNIGHT_HOURS && encodedEndTime !== MIDNIGHT_HOURS_24H) {
      setEndTime(null);
      const queryParams = encodedStartTime ? { ...restQueryParams, startTime: encodedStartTime } : restQueryParams;
      const queryString = qs.stringify(
        pick(
          queryParams,
          QUERY_PARAMS.filter(param => param !== 'duration'),
        ),
      );

      dispatch(replace(`${location.pathname}?${queryString}`));
    }
  }, [startTime, endTime, setEndTime, encodedStartTime, encodedEndTime, restQueryParams, dispatch]);

  return { startTime, endTime, ...restTimeValues };
};

interface UseSetTimeQueryParam {
  time: Date;
  queryParamName: string;
}

const useSetTimeQueryParam = ({ time, queryParamName }: UseSetTimeQueryParam) => {
  const locale = useLocale();
  const dispatch = useDispatch();
  const encodedQueryParams = useSearchParams<searchParams>();

  return useCallback(
    (timeValue?: Date) => {
      const selectedTime = timeValue || time;
      if (!selectedTime) {
        return;
      }
      const isEndTimeMidnight =
        queryParamName === 'endTime' && timeValue.getHours() === 0 && timeValue.getMinutes() === 0;
      const hourFormat = isEndTimeMidnight && {
        hour12: false,
      };

      const formattedTimeValue = isEndTimeMidnight
        ? resolveSafariMidnight(selectedTime, { locale, timeFormatOptions, hourFormat })
        : selectedTime.toLocaleString(locale, {
            ...timeFormatOptions,
            ...hourFormat,
          });

      const queryParams = { ...encodedQueryParams, [queryParamName]: formattedTimeValue };
      const queryString = qs.stringify(queryParams);

      dispatch(replace(`${location.pathname}?${queryString}`));
    },
    [time, locale, encodedQueryParams, queryParamName, dispatch],
  );
};

const useGetClosestTimeSlot = (timeValues: Item[]) => {
  const closestTimeSlot = getClosestTimeSlot();
  const closestTimeSlotValue = useMemo(
    () => timeValues.find(({ value }) => value.toString() === closestTimeSlot.toString())?.value,
    [closestTimeSlot, timeValues],
  );

  return closestTimeSlotValue ?? null;
};

const useDefaultValues = (
  startTimeValues: Array<Item>,
  endTimeValues: Array<Item>,
  isStartTimePickerActive: boolean,
  isEndTimePickerActive: boolean,
) => {
  const [defaultStartValue, setDefaultStartValue] = useState<Date>(null);
  const [defaultEndValue, setDefaultEndValue] = useState<Date>(null);
  const { startTime: encodedStartTime, endTime: encodedEndTime } = useSearchParams<searchParams>();
  const locale = useLocale();
  const closestTimeSlot = useGetClosestTimeSlot(startTimeValues);

  useEffect(() => {
    if (isStartTimePickerActive) {
      if (encodedStartTime) {
        const newTime = startTimeValues.find(
          ({ value }) => value.toLocaleString(locale, timeFormatOptions) === encodedStartTime,
        );
        setDefaultStartValue(newTime?.value);
      }
      if (!encodedStartTime) {
        setDefaultStartValue(closestTimeSlot);
      }
    }
  }, [isStartTimePickerActive, encodedStartTime, startTimeValues, locale, closestTimeSlot]);

  useEffect(() => {
    if (isEndTimePickerActive) {
      if (encodedEndTime) {
        const newTime = endTimeValues.find(
          ({ value }) => value.toLocaleString(locale, timeFormatOptions) === encodedEndTime,
        );
        setDefaultEndValue(newTime?.value);
      }
      if (!encodedEndTime) {
        setDefaultEndValue(endTimeValues[0]?.value);
      }
    }
  }, [isEndTimePickerActive, endTimeValues, locale, encodedEndTime]);

  return { defaultStartValue, defaultEndValue };
};

export const useMobileFilterTimeSelector = (
  toggleOverlay: VoidFunction,
  options: UseTimeSelectorOptions = useTimeSelectorDefaultOptions,
) => {
  const {
    startTime,
    endTime,
    startTimeValues: originalStartTimeValues,
    endTimeValues,
    ...restTimeValues
  } = useTimeFilter();

  const setStartTimeQueryParam = useSetTimeQueryParam({ time: startTime, queryParamName: 'startTime' });
  const setEndTimeQueryParam = useSetTimeQueryParam({ time: endTime, queryParamName: 'endTime' });

  const startTimeValues = useMemo(() => {
    if (!options.filterPastTimes) return originalStartTimeValues;

    const now = new Date();

    return originalStartTimeValues.filter(({ value }) => !isSameDay(value, now) || value > now);
  }, [originalStartTimeValues, options.filterPastTimes]);

  const closestTimeSlot = useGetClosestTimeSlot(startTimeValues);

  const { isStartTimePickerActive, isEndTimePickerActive, ...timePickerActions } = useMobileTimePickerDropdowns({
    startTime: startTime ?? closestTimeSlot,
    endTime: endTime ?? endTimeValues[0]?.value,
    toggleOverlay,
  });

  const defaultValues = useDefaultValues(
    startTimeValues,
    endTimeValues,
    isStartTimePickerActive,
    isEndTimePickerActive,
  );

  useEffect(() => {
    if (!isStartTimePickerActive && startTime) {
      setStartTimeQueryParam();
    }
  }, [isStartTimePickerActive, setStartTimeQueryParam, startTime]);

  useEffect(() => {
    if (!isEndTimePickerActive && endTime) {
      setEndTimeQueryParam(endTime);
    }
  }, [isEndTimePickerActive, endTime, setEndTimeQueryParam]);

  return {
    startTime,
    endTime,
    startTimeValues,
    endTimeValues,
    closestTimeSlot,
    isStartTimePickerActive,
    isEndTimePickerActive,
    defaultValues,
    ...restTimeValues,
    ...timePickerActions,
  };
};
