import { ERROR_PATH, ROOT_PATH } from 'shared/consts/const';
import { Route, Routes } from 'react-router-dom';

import { AppContentWrapper } from 'components/app-content-wrapper/app-content-wrapper';
import { DefaultRedirect } from './default-redirect';
import { ErrorHandler } from 'hocs/errorHandler';
import { ErrorPage } from 'pages/error';
import { LandingPage } from 'pages/landing';
import { PrivateRoute } from 'components/private-route';
import React, { lazy, Suspense } from 'react';
import { RedirectPage } from 'components/payment/redirect';
import * as Sentry from '@sentry/react';
import NotificationsContainer from 'components/notifications/notifications-container';
import useHasFloorPlan from 'floorplan/pages/floorplan/hooks/use-has-floor-plan';
import { useRedirectUserToLanding } from './hooks/use-redirect-user-to-landing.hook';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const ResourcePage = lazy(() => import('pages/resource/resource'));
const AdditionalInformationFlex = lazy(() => import('pages/additional-information-flex/AdditionalInformationFlex'));
const RoomLayoutPage = lazy(() => import('pages/room-layout/room-layout'));
const AddOnsPage = lazy(() => import('pages/add-ons/add-ons'));
const ReceiptPage = lazy(() => import('components/payment/receipt/receiptPage'));
const ResourcesPage = lazy(() => import('pages/resources/resources'));
const FloorPlanPage = lazy(() => import('../../floorplan/pages/floorplan/floorplan'));
const DeepLinkHandlePage = lazy(() => import('pages/deeplink/deeplink'));

export const Router: React.FC = (): JSX.Element => {
  const floorPlanEnabled = useHasFloorPlan();
  useRedirectUserToLanding();

  return (
    <AppContentWrapper>
      <ErrorHandler>
        <>
          <NotificationsContainer />
          <SentryRoutes>
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <LandingPage />
                </PrivateRoute>
              }
            />
            <Route
              path="resource-booking/resource/:resourceUuid/*"
              element={
                <PrivateRoute>
                  <Suspense fallback={null}>
                    <ResourcePage />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="resource-booking/resource/:resourceUuid/additional-information/*"
              element={
                <PrivateRoute>
                  <Suspense fallback={null}>
                    <AdditionalInformationFlex />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="resource-booking/resource/:resourceUuid/room-layouts/*"
              element={
                <PrivateRoute>
                  <Suspense fallback={null}>
                    <RoomLayoutPage />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="resource-booking/resource/:resourceUuid/add-ons/*"
              element={
                <PrivateRoute>
                  <Suspense fallback={null}>
                    <AddOnsPage />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="receipt/:transactionUuid"
              element={
                <PrivateRoute>
                  <Suspense fallback={null}>
                    <ReceiptPage />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path={ROOT_PATH}
              element={
                <PrivateRoute>
                  <Suspense fallback={null}>
                    <ResourcesPage />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="floorplan/*"
              element={
                floorPlanEnabled ? (
                  <PrivateRoute>
                    <Suspense fallback={null}>
                      <FloorPlanPage />
                    </Suspense>
                  </PrivateRoute>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="floorplan/:floorUuid/*"
              element={
                floorPlanEnabled ? (
                  <PrivateRoute>
                    <Suspense fallback={null}>
                      <FloorPlanPage />
                    </Suspense>
                  </PrivateRoute>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="deeplink/:payload"
              element={
                <Suspense fallback={null}>
                  <DeepLinkHandlePage />
                </Suspense>
              }
            />
            <Route path="redirect" element={<RedirectPage />} />
            <Route path={ERROR_PATH} element={<ErrorPage />} />
            <Route path="*" element={<DefaultRedirect />} />
          </SentryRoutes>
        </>
      </ErrorHandler>
    </AppContentWrapper>
  );
};
